@import '../../../styles/customMediaQueries.css';

.listingNavigation {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 10px;

  @media (max-width:768px) {
    padding: 12px;
    gap: 10px;
  }

  & li {
    min-width: fit-content;

    & a {
      color: #9AA0A6;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      text-decoration: none;

      @media(max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.tagLinesName {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #262626;
  font-family: 'Poppins', sans-serif;
}

.subInformationB {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media(max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & h4 {
    font-weight: 700;
    font-weight: 500;
    line-height: 1.2;
    color: #9AA0A6;
    font-size: 16px;
    margin-top: 0;
  }

  & .specialitiesDeatail {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 16px;
  }
}

.specialitiesBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media(max-width: 768px) {
    overflow-x: auto;
  }
}

.specialitiesCard {
  font-size: 14px;
  padding: 1px 9px;
  border-radius: 59px;
  background-color: #F5F8FA;
  border: 0.5px solid #CCCCCC;
  color: #363E46;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.serviceCard {
  gap: 20px;
  display: flex;
  padding: 24px 24px 48px 24px;
  margin: 1rem 0;
  border-radius: 5px;
  align-items: center;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.125);

  @media(max-width: 768px) {
    flex-wrap: wrap;
  }

  & .titleAmount {
    font-weight: 600;
    line-height: 1.2;
    color: #000;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  & .descriptionText {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #262626;
    font-family: 'Poppins', sans-serif;
  }
}

.orderBtn {
  padding: 10px;
  width: 170px;
}

.rushService{
  background-color: #6df003;
  border-color: #6df003;

  &:hover {
    color: #6df003;
  }
}

.advisorOffline{
  background-color: rgb(176, 173, 173);
  border-color: rgb(176, 173, 173);

  &:hover {
    color: rgb(176, 173, 173);
  }
}

.imageSlider {
  display: flex;
  overflow-x: auto;
  width: 100%;

  @media(max-width: 560px) {
    /* flex-wrap: wrap; */
    gap: 10px;
  }

  & img {
    width: 220px;
    display: inline-grid;
    margin: 0 15px 0 0;

    @media(max-width: 560px) {
      width: 85%;
    }
  }
}


.orderFormBoxA {
  /* display: flex;
  gap: 1rem;
  flex-direction: column; */

  @media (max-width:768px) {
    flex-direction: column;
  }

  & .inputBox {
    margin-bottom: 20px;

    & label {
      margin-left: 0;
    }
  }
}

.checkboxServices {
  /* margin-top: 15px; */
}

.descriptionInput {
  margin-top: 15px;

  & textarea {
    &::placeholder {
      font-size: 12px;
    }
  }
}

.addImageWrapper {
  height: 100px;
  width: 100px;
}

.addImageInput {
  display: none;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 5px;
  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    font-size: 10px;
  }
}


.imageTypes {
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageSection {
  display: flex;
  margin-block: 1rem;
  gap: 1rem;

  & h6 {
    margin: 0;
    padding: 0;
  }

  & .modalImageSection {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;

    @media (max-width:560px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.orderAddedImage {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 2px;

  & img {
    width: 100%;
    border-radius: 2px;
    height: 100%;
    object-fit: cover;
  }
}

.closeIcon {
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #999696a1;
  border: none;

  & svg {
    stroke: #02070c;
  }
}

.headingBox {
  & h6 {
    color: #9AA0A6;
    font-size: 15px;
    margin-bottom: 0.25rem;
    margin-top: 0;
    font-weight: 400;
    padding: 0;
    text-transform: capitalize;
  }

  & h4 {
    font-size: 30px;
    line-height: 1.23;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

.serviceBox {

  @media (max-width:768px) {
    padding: 0 24px;
  }

  & .serviceTitle {
    font-weight: 500;
    line-height: 1.2;
    color: var(--colorGrey700);
    font-size: 20px;
  }
}

.meetBox {
  & .meetMeTitle {
    font-weight: 500;
    line-height: 1.2;
    color: var(--colorGrey700);
    font-size: 20px;
  }
}

.upsell {
  margin-top: 1rem;
}

.selectedTab {
  border-bottom: 3px solid #FF6900;
  text-decoration: none;
  color: #000 !important;
}

.tab {
  color: #000;
  font-size: 1.2rem;
}

.tab:hover {
  border-bottom: 3px solid #FF6900;
  text-decoration: none;
  color: #000;
}

.refundWrapper {
  @media (max-width:768px) {
    padding: 0 24px 40px 24px;
  }
}

.refundHeading {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 10px 0 16px 0;
}

.refundDetails {
  color: #000;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.meetSection {
  @media (max-width:768px) {
    padding: 0 24px;
  }
}

.meetHeading {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.sectionHeading {
  & span {
    font-size: 20px;
    color: #000;
    margin-top: 10px;
    font-weight: 600;
    margin: 0 0 16px 0;
  }
}

.serviceHeading {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  font-weight: 600;
}

.listingHeader {

  @media(max-width:768px) {
    padding: 0 20px;
  }

  & h4 {
    font-size: 30px;
    color: #000;
    font-weight: 400;
    margin: 0;

  }

  & h6 {
    color: #9AA0A6;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-bottom: 8px;
  }
}

.saveMsgError {
  color: red;
  font-size: 14px;
}

.updateMessage {
  margin-top: 20px;

  & label {
    & span {
      font-size: 13px;
    }
  }
}

.informationContainer {
  @media (max-width:768px) {
    padding: 0 24px;
  }
}

.servicesLoader {
  text-align: center;
  padding: 1rem;
}


.customDropdown {
  position: relative;
  margin-bottom: 1rem;

  & .selectDropdown {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #212529; */
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!--%20License%3A%20PD.%20Made%20by%20CFPB%3A%20https%3A%2F%2Fgithub.com%2Fcfpb%2Fdesign-system%20--%3E%3Csvg%20fill%3D%22%23000000%22%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%22-1%200%2019%2019%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22cf-icon-svg%22%3E%3Cpath%20d%3D%22M8.5%2015.313a1.026%201.026%200%200%201-.728-.302l-6.8-6.8a1.03%201.03%200%200%201%201.455-1.456L8.5%2012.828l6.073-6.073a1.03%201.03%200%200%201%201.455%201.456l-6.8%206.8a1.026%201.026%200%200%201-.728.302z%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
  }

  .default {
    color: #fff !important;
    background-color: #f57158 !important;
  }

  & .dropdownList {
    position: absolute;
    left: 0;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    top: 38px;
    margin: 0;
    border: 1px solid #ced4da;

    & li {
      padding: 5px 15px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: #fff;
        background-color: #f57158;
      }
    }

  }

}

.paragraph {
  margin-block: 8px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #262626;
  font-family: 'Poppins', sans-serif;
}
.buttonWrapper {
  display: flex;
  gap: 40px;
  margin-top: 16px;

  & .noButton {
    background-color: transparent;
    border: 1px solid  var(--marketplaceColor);
    color: var(--marketplaceColor);
  }
}
.advisorModal {
  &>div>div {
    &>button {
      display: none;
    }
  }
}
.root {
  fill: var(--colorGrey100);
}

.filled {
  fill: var(--marketplaceColor);
}

.ratingBox {
  gap: 5px;
  display: flex;
  align-items: center;
  color: #f57158;
}
@import '../../styles/customMediaQueries.css';
.couponForm {
  width: 40%;
  margin-left: auto;
  padding-top: 20px;
  @media (--viewportLarge) {
    padding: 20px 48px 0;
  }
}

.couponForm label {
  padding: 0;
}
.couponForm label h4 {
  margin: 0;
}

.couponFromLabel{
   align-self: flex-end;
}

.couponInput{
  display: flex;
  gap: 10px;
    & input{
      
    }
}

.couponBtn{
  border-radius: 6px;
  background-color: #f57158;
  border: 1px solid #f57158;
  color: white;
  cursor: pointer;
  transition: all .4s ease-in-out;
&:hover{
  background-color: #fff;
  border: 1px solid #f57158;
  color: #f57158;
}
}
.addCouponError{
  color: red;
  margin: 0;
}
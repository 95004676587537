.listingCardBox {
    border: 1px solid #EBEDEE;
    transition: all 0.15s ease-in-out;
    border-radius: 0.25rem;
    overflow: hidden;

    &:hover {
        border-color: #F57158;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    }

    & .cardImage {
        height: 200px;

        & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    & .advisorBody {
        padding: 21px 13px 24px 13px;

        & .advisorName {
            font-size: 13px;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #9AA0A6;
            font-weight: 400;
        }

        & .titleName {
            font-size: 15px;
            font-weight: 700;
            line-height: normal;
            margin-top: 5px;
            height: 38px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #363e46;
        }

        & .startingPrice {
            color: #F57158;
            font-weight: 600;
            font-size: 14px;
        }
    }
}
/* CountdownTimer.module.css */
.red {
    color: rgb(185, 5, 5);

    &>div {
        display: flex;
        gap: 10px;

        & span {
            background: #fff;
            padding: 8px;
            border: 1px solid rgb(185, 5, 5);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            font-size: 12px;
        }
    }
}

.yellow {
    color: rgb(209, 206, 1);
    text-shadow:  1px #b9b9b9;

    &>div {
        display: flex;
        gap: 10px;

        & span {
            background: #fff;
            padding: 8px;
            border: 1px solid rgb(187, 187, 0);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            font-size: 12px;
        }
    }
}

.green {
    color: rgb(4, 153, 4);

    &>div {
        display: flex;
        gap: 10px;

        & span {
            background: #fff;
            padding: 8px;
            border: 1px solid rgb(4, 153, 4);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            font-size: 12px;
        }
    }
}
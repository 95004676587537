@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}



.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  /* display: flex;
  flex-direction: column; */
  margin-top: 0;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingCardBox {
  border: 1px solid #EBEDEE;
  transition: all 0.15s ease-in-out;
  border-radius: 0.25rem;
  overflow: hidden;


  &:hover {
    border-color: #F57158;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    text-decoration: none;
  }

  & .cardImage {

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & .advisorBody {
    padding: 21px 13px 24px 13px;
    position: relative;

    & .advisorName {
      font-size: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #919293;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 6px;
    }

    & .titleName {
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #363e46;
    }

    & .startingPrice {
      color: #F57158;
      font-weight: 600;
      font-size: 14px;
    }

    & .bottomPriceWrapper {
      /* margin-top: 10px; */
      display: flex;
      align-items: center;

      & .ratingStar {
        display: flex;
        gap: 5px;
        margin-right: 5px;

        & svg {
          width: 16px;
          height: 16px;
        }
      }
    }

  }
}

.reviews {

  & p,
  span {
    font-size: 12px;
    color: gray;
    margin: 0;
  }

  & p::before {
    content: '(';
  }

  & p::after {
    content: ')';
  }
}

.onlineDot {
  display: flex;
  align-items: center;

  &>div {
    position: relative;
    top: -29px;
    left: -22px;
  }

  & .onlineText {
    /* font-size: 12px; */
    color: #6df003;
    margin-left: 26px;
    text-transform: lowercase;
  }
}
.ring-container {
    position: relative;
}

.circle {
    width: 10px;
    height: 10px;
    background-color: #6df003;
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: 23px;
}

.ringring {
    border: 3px solid #6df003;
    border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    left: 15px;
    top: 15px;
    animation: pulsate 1s ease-out infinite;
    opacity: 0.0;
}

@keyframes pulsate {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}

/* Vendor Prefixes for animation */
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-moz-keyframes pulsate {
    0% {-moz-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-moz-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-ms-keyframes pulsate {
    0% {-ms-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-ms-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-o-keyframes pulsate {
    0% {-o-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-o-transform: scale(1.2, 1.2); opacity: 0.0;}
}
